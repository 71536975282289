//
//
//
//
//
//
//
//

import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "Description",
  components: {},
  props: {
    color:{
      type: String,
      default: '#868686'
    },
    placeholder: {
      type: String,
      default: '只是一段说明文字'
    }
  },
  data() {
    return {}
  },
  methods: {}
}
